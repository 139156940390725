import { Launch } from '@mui/icons-material'
import { Link } from '@mui/material'
import { getDirectionUrl } from 'strikemap-common/lib/getDirectionUrl'
import { GMapsLocation } from 'strikemap-common/lib/types'

interface Props {
  location: GMapsLocation
  origin?: GMapsLocation
  target: string
  text?: string | null
}

export default function Direction({ location, target, text, origin }: Props) {
  return (
    <Link
      color="textPrimary"
      variant="body2"
      fontWeight={600}
      href={getDirectionUrl(location, origin)}
      target={target}
    >
      {text || 'DIRECTIONS'}
      <Launch sx={{ margin: '0 0 -4px 5px', fontSize: 18 }} />
    </Link>
  )
}
