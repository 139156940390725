import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import parse from 'html-react-parser'
import RootContext from 'lib/browser/RootContext'
import _ from 'lodash'
import { useContext } from 'react'
import { CheckRow, Root } from './styled'

export default function Consents() {
  const { campaign } = useContext(RootContext)
  const consents = campaign
    ? [{ identifier: campaign.identifier, label: campaign.consent_text }]
    : []
  const [state, setState] = useState(
    _.keyBy(
      consents.map((consent) => ({ ...consent, checked: false })),
      'identifier'
    )
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: !state[event.target.name] })
  }

  return (
    <Root>
      <FormControl component="fieldset">
        <FormGroup>
          {consents.map((consent) => (
            <CheckRow key={consent.identifier}>
              <div>
                {consent.label && parse(parse(consent.label).toString())}
              </div>
              <Checkbox
                checked={state[consent.identifier]}
                onChange={handleChange}
                name={consent.identifier}
              />
            </CheckRow>
          ))}
        </FormGroup>
      </FormControl>
    </Root>
  )
}
