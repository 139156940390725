import { Box, Fade, Grid, Link, useMediaQuery, useTheme } from '@mui/material'
import ActionMenu from 'components/organisms/ActionMenu'
import GoToStrike from 'components/organisms/GoToStrike'
import {
  CAMPAIGN_BANNER_HEIGHT,
  HEADER_HEIGHT,
  MAP_CENTER,
  MAP_WIDE_ZOOM,
} from 'lib'
import { useCampaigns } from 'lib/browser/CampaignProvider'
import { StrikeContext } from 'lib/browser/StrikeProvider'
import Image from 'next/legacy/image'
import { default as NextLink } from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useMap } from 'react-map-gl'
import { Brand } from 'strikemap-common/lib/types'
import CampaignBanner from '../../molecules/CampaignBanner'
import { FloatingSearch, Root } from './styled'

interface HeaderProps {
  hideSearch?: boolean
  hideMenu?: boolean
  brand?: Brand
}

const Header = ({ hideSearch, hideMenu, brand }: HeaderProps) => {
  const theme = useTheme()
  const router = useRouter()
  const { default: map } = useMap()
  const { menuOpen, activeSearch, embed } = useContext(StrikeContext)
  const { featuredCampaign } = useCampaigns()
  const desktop = useMediaQuery(theme.breakpoints.up('md')) && !embed

  return (
    <>
      <Root
        theme={theme}
        hideSearch={!!hideSearch}
        desktop={desktop}
        bg={brand?.colours?.strikeCard || theme.palette.primary.main}
        border={theme.palette.secondary.main}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <NextLink
            href={embed ? '#' : '/'}
            onClick={embed ? () => router.back() : onClickHome}
          >
            <Link component="div">
              <Image alt="logo" src="/logo-line.png" width={207} height={45} />
            </Link>
          </NextLink>

          {!hideMenu && !embed && <ActionMenu />}
        </Grid>
        {featuredCampaign && !desktop && (
          <Fade in={!menuOpen} timeout={{ enter: 700, exit: 300 }}>
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                top: HEADER_HEIGHT - 1,
                left: 0,
                zIndex: 1000,
              }}
            >
              <CampaignBanner campaign={featuredCampaign} />
            </Box>
          </Fade>
        )}
        {desktop && !hideSearch ? (
          <Grid container>
            <GoToStrike defaultValue={activeSearch} hide={hideSearch} />
          </Grid>
        ) : (
          <Fade in={!menuOpen && !hideSearch}>
            <FloatingSearch
              offset={featuredCampaign ? CAMPAIGN_BANNER_HEIGHT : 0}
            >
              <GoToStrike defaultValue={activeSearch} hide={hideSearch} />
            </FloatingSearch>
          </Fade>
        )}
      </Root>
    </>
  )

  function onClickHome() {
    if (map) {
      map.setZoom(MAP_WIDE_ZOOM)
      map.setCenter(MAP_CENTER)
    }
  }
}

export default Header
