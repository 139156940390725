import styled from '@emotion/styled'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Grid, IconButton, SxProps, Tooltip, Typography } from '@mui/material'
import Bullet from 'components/atoms/Bullet'
import dayjs from 'dayjs'
import { SIDEBAR_WIDTH } from 'lib'
import { getDistance } from 'lib/browser/geo'
import { startCase } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { GMapCoord, StrikePublic } from 'strikemap-common/lib/types'
import { checkLocationPermission } from 'strikemap-common/lib/useLocation'
import Direction from './Direction'

const XTooltip = styled(Tooltip)``

XTooltip.defaultProps = { enterTouchDelay: 0, leaveTouchDelay: 700 }

interface Props {
  strike: StrikePublic
  onClickArrow?: () => void
  sx?: SxProps
  arrowUp?: boolean
  directionsLink?: boolean
  condensed?: boolean
}

export default function StrikeDetails({
  strike,
  onClickArrow,
  sx,
  arrowUp,
  directionsLink = true,
  condensed,
}: Props) {
  const [location, setLocation] = useState<GMapCoord>()

  useEffect(() => {
    let mounted = true
    checkLocationPermission(navigator).then((permission) => {
      if (permission !== 'denied' && mounted === true) {
        navigator.geolocation.getCurrentPosition((position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        })
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const topLine = [
    directionsLink && !condensed && (
      <Direction
        key="directions"
        location={strike.location}
        target={'_blank'}
        text={strike?.action_postcode}
      />
    ),
    !!strike.constituency && (
      <XTooltip title={strike.constituency || ''}>
        <Typography
          variant="body2"
          noWrap
          sx={{ maxWidth: '40%' }}
          key="constituency"
        >
          {strike.constituency}
        </Typography>
      </XTooltip>
    ),
    !!location && !condensed && (
      <Typography variant="body2" key="distance">
        {(getDistance(location, strike) / 1000).toFixed(1)}km
      </Typography>
    ),
  ].filter(Boolean)

  const middleLine = [
    strike.strike_status && (
      <Typography variant="body2" key="strike-status">
        {startCase(strike.strike_status?.toLowerCase())}
      </Typography>
    ),
    <Typography variant="body2" key="strike-start">
      {dayjs(strike.action_start).format('MMM D, YYYY')}
    </Typography>,
  ].filter(Boolean)

  return (
    <Grid
      container
      direction="column"
      spacing={0}
      sx={{
        maxWidth: '100% !important',
        ...sx,
      }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <XTooltip title={strike.employer_name || ''} enterTouchDelay={100}>
          <Typography
            variant="h2"
            noWrap
            sx={{
              maxWidth: onClickArrow ? '75%' : '90%',
              lineHeight: '32px',
              marginBottom: '10px',
            }}
          >
            {strike.employer_name}
          </Typography>
        </XTooltip>

        {onClickArrow && (
          <IconButton
            onClick={onClickArrow}
            size="small"
            sx={{ marginTop: '-10px' }}
          >
            {arrowUp ? (
              <KeyboardArrowUp sx={{ fontSize: 32, color: '#1C1B1F' }} />
            ) : (
              <KeyboardArrowDown sx={{ fontSize: 32, color: '#1C1B1F' }} />
            )}
          </IconButton>
        )}
      </Grid>
      {!condensed && (
        <Grid item container direction="row">
          {topLine.map((item, idx) => (
            <Fragment key={'top' + idx}>
              {!!idx && <Bullet />}
              {item}
            </Fragment>
          ))}
        </Grid>
      )}
      <Grid item container direction="row">
        {middleLine.map((item, idx) => (
          <Fragment key={'top' + idx}>
            {!!idx && <Bullet />}
            {item}
          </Fragment>
        ))}
      </Grid>
      {!condensed && (
        <Grid item sx={{ display: 'grid' }}>
          <XTooltip title={strike.trade_unions_taking_action || ''}>
            <Typography
              variant="body2"
              noWrap
              sx={{ maxWidth: SIDEBAR_WIDTH * 0.85 }}
            >
              {strike.trade_unions_taking_action}
            </Typography>
          </XTooltip>
        </Grid>
      )}
    </Grid>
  )
}
