import { NextRouter } from 'next/router'

export const onClickMarker = (id: number, router: NextRouter) => {
  const embedKey = router.query.embedKey
  let parent = ''
  if (router.pathname.includes('/union/[slug]'))
    parent = `/union/${router.query.slug}`
  if (router.pathname.includes('/s/[slug]')) parent = `/s/${router.query.slug}`
  if (router.pathname.includes('/d/[disputeId]'))
    parent = `/d/${router.query.disputeId}`
  if (router.pathname.includes('/w/[waveId]'))
    parent = `/w/${router.query.waveId}`
  if (router.pathname.includes('/search')) parent = `/search`
  router.push(
    `${parent}/strikes/${id}${embedKey ? `?embedKey=${embedKey}` : ''}`
  )
}
