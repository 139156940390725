import { ButtonProps } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import {
  campaign,
  config,
  dispute,
  job,
  organisation,
  organisationMembership,
  place,
  strike,
  supportMessage,
  team,
  union,
  wave,
} from '@prisma/client'

export interface Errors {
  [key: string]: string
}

export interface GMapCoord {
  lat: number
  lng: number
}

// Fields that are sensitive / not UI-relevant
export const strikePrivateFields = <const>[
  'union_rep',
  'submitter_email',
  'approved',
  'representative',
  'representativeId',
  'campaigns',
  'geom',
]

// Datetime fields can't be serialized bynext.js server side
// methods like GetStaticProps, so they need to be converted to ISO strings
export const strikeDateFields = <const>[
  'created_at',
  'createdAt',
  'updatedAt',
  'action_start',
  'action_end',
]

export const strikeStatuses = <const>['ACTIVE', 'UPCOMING', 'ENDED']

type SupportMessageEmbedServices = 'boast.io'

export interface SupportMessageHydrated
  extends Omit<supportMessage, 'created_at'> {
  embed: { [Key in SupportMessageEmbedServices]: { url: string } }
  created_at: string
}

type StrikeExtraFields = {
  action_start: string
  action_end: string
  strike_status: typeof strikeStatuses | null
  location: {
    lat: number
    lng: number
  }
  created_at: string
  union?: UnionPopulated
  supportMessages: SupportMessageHydrated[]
  wave?: WavePopulated & {
    dispute?: DisputePopulated & { union?: UnionPopulated }
  }
}

export type StrikePrivate = Omit<strike, (typeof strikeDateFields)[number]> &
  StrikeExtraFields

export type StrikePublic = Omit<
  strike,
  | (typeof strikePrivateFields)[number]
  | (typeof strikeDateFields)[number]
  | 'supportMessages'
> &
  StrikeExtraFields

export type Campaign = Omit<campaign, 'brand' | 'content'> & {
  brand: Brand
  content: {
    cardText?: string
    cardUrl?: string
    cardUrlLabel?: string
  }
  places: Place[]
}

interface MuiButtonProps extends ButtonProps {
  target?: string
}

export interface Action {
  title: string
  props?: MuiButtonProps
  icon(): React.ReactNode
  dialog?: {
    content: React.ReactNode
    actions?: React.ReactNode
  }
  widget?: boolean
}

export interface MapOptions {
  center?: GMapCoord
  zoom?: number
}

export interface Consent {
  identifier: string
  label: string
  action: string
}

export interface UserDetails {
  [index: string]: any
  name?: string
  email?: string
  address?: string
  postcode?: string
}

export interface Representative {
  name: string
  email: string
}

export interface ScriptWidget {
  script: string
  stylesheet: string
  targetElementId: string
}

export interface StrikeMenuItem {
  Icon: React.Component | OverridableComponent<any>
  label: string
  href?: string
}

export interface PostcodeIoLookup {
  region: string
  parliamentary_constituency: string
  country: string
  latitude: string
  longitude: string
  postcode: string
}

export interface PostcodeIoResponse {
  status: string | number
  result: PostcodeIoLookup | null
}

export interface PostcodeIoBulkResponse {
  status: string | number
  result: { query: string; result: PostcodeIoLookup | null }[]
}

export interface Employer {
  name: string
}

export type EmployerName = string | null

export interface UnionPopulated extends Omit<union, 'brand' | 'features'> {
  disputes: DisputePopulated[]
  brand: Brand
  features: StrikeFeatures
  teams: team[]
  name: string
}

export interface StrikeFeatures {
  supportMessages: {
    text?: boolean
    image?: boolean
    embed?: keyof SupportMessageEmbedServices
    forwardUrl?: string
  }
  solidarityEmails: boolean
  visitPicket: boolean
  embed?: {
    noHeader?: boolean
  }
  massActions?: boolean
  boast?: {
    feed?: string
    form?: string
  }
  restrictToEmbed?: boolean
}

export interface DisputePopulated extends Omit<dispute, 'features'> {
  waves: WavePopulated[]
  features?: StrikeFeatures
}

export interface WavePopulated extends wave {
  strikes: strike[]
}

export const defaultBrand: Brand = {
  colours: {
    mapClusters: '',
    strikeCard: '',
    buttons: '',
    darkText: '',
  },
  fonts: {
    google: { typeface: 'Roboto' },
  },
  map: {
    marker: '',
  },
}

export interface Brand {
  colours?: {
    mapClusters?: string
    strikeCard?: string
    buttons?: string
    darkText?: string
  }
  fonts?: {
    google: { typeface: string }
  }
  map?: {
    marker: string
  }
}

export interface StrikeCluster<S> {
  id: number
  properties: {
    cluster: boolean
    strikeId: number
    strike: S
    point_count: number
  }
  geometry: {
    coordinates: number[]
  }
}

export interface GenericMarker {
  id: number | string
  [key: string]: any
}

export type MapOverride = {
  Marker: (Props: GenericMarker) => JSX.Element
  data: GenericMarker[]
}

export interface JobPopulated extends Omit<job, 'bullmq'> {
  bullmq: { nChunks: number }
}

export type StrikesMetaType = {
  total: number
  noLocation: number
  live: number
  id: number
}

export type GlobalConfig = {
  featuredCampaignId: number
}

export type Organisation = organisation
export type OrganisationMembership = organisationMembership
export type Place = place
export type Setting = config
export interface GMapCoord {
  lat: number
  lng: number
}
export type GMapsLocation = {
  lat: number
  lng: number
}
