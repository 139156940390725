import { useCampaigns } from 'lib/browser/CampaignProvider'
import { flatten } from 'lodash'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useRouter } from 'next/router'
import React from 'react'
import { Marker } from 'react-map-gl'
import CampaignMarker from 'strikemap-common/components/CampaignMarker'

const CampaignMarkers: React.FC = () => {
  const router = useRouter()
  const { featuredCampaign, activeCampaigns } = useCampaigns()
  const allMarkers = [...(activeCampaigns || []), featuredCampaign]

  if (router.route !== '/') return null

  return (
    <>
      {flatten(
        allMarkers?.map((campaign) => {
          return campaign?.places?.map((place) => {
            const { latitude, longitude } = place
            const hasName = !!place.name
            const placeName = hasName
              ? place.name
              : place.address?.split(',')[0]
            const address = hasName
              ? place.address
              : place.address?.split((placeName + ',') as string)[1]

            return (
              <Marker
                latitude={Number(latitude)}
                longitude={Number(longitude)}
                key={`campaign-${campaign.id}-marker-${place.id}}`}
                anchor="center"
              >
                <CampaignMarker
                  id={place.identifier}
                  campaign={campaign}
                  customMarker={campaign.brand?.map?.marker}
                  title={campaign.title}
                  url={campaign.content.cardUrl}
                  urlLabel={campaign.content.cardUrlLabel}
                  placeName={placeName}
                  address={address}
                  content={campaign.content.cardText}
                />
              </Marker>
            )
          })
        })
      )}
    </>
  )
}

export default CampaignMarkers
