import styled from '@emotion/styled'
import {
  Grid,
  Popover,
  SvgIconProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import StrikeDetails from 'components/organisms/StrikeCard/StrikeDetails'
import StrikeTags from 'components/organisms/StrikeCard/StrikeTags'
import Image from 'next/image'
import { useState } from 'react'
import { StrikePublic } from 'strikemap-common/lib/types'
import MarkerIcon from './StrikeIcons/MarkerIcon'

export const MarkerStyled = styled.div`
  max-width: 45px;
  width: 36px;
  height: 36px;
  position: relative;
  overflow: hidden;
  //filter: drop-shadow(6px 6px 7px #797373);
  cursor: pointer;
`

interface StrikeMarkerProps {
  id: number
  onSelect: (id: number) => void
  lat?: number
  lng?: number
  strike: StrikePublic
  customMarker?: string
}

const StrikeMarker = ({
  id,
  onSelect,
  strike,
  customMarker,
}: StrikeMarkerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  const handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  let style: SvgIconProps['style'] = { height: 36, width: 36 }
  if (strike.strike_status === 'ENDED') style = { ...style, color: 'grey' }
  if (strike.strike_status === 'UPCOMING')
    style = { ...style, color: theme.palette.primary.main }

  return (
    <>
      <MarkerStyled
        onClick={() => onSelect(id)}
        onMouseEnter={desktop ? handleHover : undefined}
        onMouseLeave={desktop ? handleClose : undefined}
      >
        {customMarker ? (
          <Image
            src={customMarker}
            alt="strike marker"
            fill
            style={{ objectFit: 'contain' }}
            loading="eager"
            unoptimized
          />
        ) : (
          <MarkerIcon style={style} />
        )}
      </MarkerStyled>
      {desktop && anchorEl && (
        <Popover
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <Grid container direction="column" sx={{ padding: '12px' }}>
            <Grid item>
              <StrikeDetails strike={strike} />
            </Grid>
            <Grid item sx={{ marginTop: 0 }}>
              <StrikeTags strike={strike} />
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  )
}

export default StrikeMarker
