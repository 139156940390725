import { Button, ButtonProps } from '@mui/material'
import { ACTION_BUTTONS } from 'lib'
import { default as NextLink } from 'next/link'

export interface ABProps {
  name: keyof typeof AB
  label?: string
  variant?: ButtonProps['variant']
  href?: string
  color?: ButtonProps['color'] | string
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AB = ACTION_BUTTONS as const
export function getActionButton(props: ABProps) {
  const buttonProps = ACTION_BUTTONS[props.name]
  const variant = props.variant || 'outlined'

  return (
    <NextLink href={props.href || buttonProps.href || ''}>
      <Button
        variant={variant}
        sx={{
          color: props.color || 'primary.main',
          borderColor: props.color || 'primary.main',
        }}
        startIcon={<buttonProps.icon />}
      >
        {props.label || buttonProps.title}
      </Button>
    </NextLink>
  )
}
