import { Coordinates } from '@mapbox/mapbox-sdk/lib/classes/mapi-request'
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding'
import {
  TextField,
  TextFieldProps as MuiTextFieldProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Fade from '@mui/material/Fade'
import { GeocodeContext } from 'lib/browser/geo'
import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { mapboxGeocodingService } from 'strikemap-common/lib/mapbox'
import { GMapCoord } from 'strikemap-common/lib/types'
import useDebounce from 'strikemap-common/lib/useDebounce'
import useLocation from 'strikemap-common/lib/useLocation'
import useSWR from 'swr'
import {
  GoIcon,
  InputButton,
  LocateIcon,
  NoLocateIcon,
  Spinner,
  StyledAutocomplete,
} from './styled'

interface Props {
  focus?: boolean
  hide?: boolean
  disabled?: boolean
  defaultValue?: string
  TextFieldProps?: MuiTextFieldProps
  onSubmitPlace?: (place: GeocodeFeature) => void
  onSelectPlace?: (place: GeocodeFeature) => void
  onClickLocate?: () => void
  noOutline?: boolean
  clearOnSubmit?: boolean
  submitOnSelect?: boolean
  noStartIcon?: boolean
  filterCountry?: string
  proximity?: GMapCoord
}

const PlaceSearch = ({
  focus,
  hide,
  disabled,
  defaultValue,
  TextFieldProps,
  onSubmitPlace,
  onClickLocate,
  onSelectPlace,
  noOutline,
  clearOnSubmit,
  submitOnSelect,
  noStartIcon,
  filterCountry,
  proximity,
}: Props) => {
  const [value, setValue] = useState<string | null>('')
  const searchRef = useRef<HTMLInputElement>(null)
  const theme = useTheme()
  const { location, locationPermission } = useLocation()

  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const debouncedValue = useDebounce(value, 500)
  const [options, setOptions] = useState<GeocodeFeature[]>([])
  const [selectedPlace, setSelectedPlace] = useState<GeocodeFeature>()

  const { data, isLoading } = useSWR(
    debouncedValue && debouncedValue?.length > 2
      ? {
          query: debouncedValue,
          proximity: proximity
            ? ([proximity?.lng, proximity?.lat] as Coordinates)
            : ([location?.lng, location?.lat] as Coordinates),
        }
      : null,
    (query) => mapboxGeocodingService.forwardGeocode(query).send()
  )

  useEffect(() => {
    clearAll()
  }, [])

  useEffect(() => {
    if (data) {
      setOptions(
        data.body.features.filter((feature) => {
          const country = (
            feature?.context?.find((c) =>
              c.id.includes('country')
            ) as GeocodeContext
          )?.short_code
          return filterCountry ? country === filterCountry : true
        })
      )
    }
  }, [data])

  useEffect(() => {
    setValue(defaultValue || null)
  }, [defaultValue])

  useEffect(() => {
    if (focus) focusSearch()
  }, [focus])

  useEffect(() => {
    if (!hide) {
      setValue(null)
      focusSearch()
    }
  }, [hide])

  const focusSearch = () => {
    if (null !== searchRef?.current && focus && desktop)
      searchRef?.current?.focus()
  }

  const onKeyDown = (e: SyntheticEvent) => {
    const evt = e.nativeEvent as KeyboardEvent
    if (
      [evt.code, evt.key].includes('Enter') &&
      !!debouncedValue &&
      selectedPlace
    ) {
      onSubmit()
    }
  }

  const onSubmit = (place?: GeocodeFeature) => {
    if (place) onSubmitPlace?.(place)
    else if (selectedPlace) onSubmitPlace?.(selectedPlace)
  }

  const clearAll = () => {
    setSelectedPlace(undefined)
    setOptions([])
    setTimeout(() => {
      setValue('')
    }, 100)
  }

  const InputProps = onSubmitPlace
    ? {
        startAdornment:
          disabled || noStartIcon ? undefined : !!debouncedValue ? (
            <>
              {isLoading ? (
                <div>
                  <Spinner color="inherit" size={22} />
                </div>
              ) : (
                <InputButton onClick={() => onSubmit()}>
                  <GoIcon />
                </InputButton>
              )}
            </>
          ) : (
            onClickLocate && (
              <InputButton onClick={onClickLocate}>
                {locationPermission === 'denied' ? (
                  <NoLocateIcon />
                ) : (
                  <LocateIcon />
                )}
              </InputButton>
            )
          ),
      }
    : {}

  return (
    <Fade in={!hide}>
      <StyledAutocomplete
        nooutline={(noOutline === true).toString()}
        fullWidth
        options={options}
        loading={isLoading}
        onInputChange={(_event, newInputValue) => {
          setValue(newInputValue)
        }}
        getOptionLabel={(option) => {
          return (option as GeocodeFeature)?.place_name || ''
        }}
        onChange={(_event, place, reason) => {
          if (reason === 'clear') {
            clearAll()
          }
          if (reason === 'selectOption') {
            if (!place) return
            if (submitOnSelect) {
              onSubmit(place as GeocodeFeature)
              if (clearOnSubmit) clearAll()
            } else {
              setSelectedPlace(place as GeocodeFeature)
              onSelectPlace?.(place as GeocodeFeature)
            }
          }
          if (reason === 'blur') {
            clearAll()
          }
        }}
        inputValue={value || ''}
        onKeyDown={onKeyDown}
        isOptionEqualToValue={(option: any, val: any) =>
          option.place_id === val.place_id
        }
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
            }}
            inputProps={{
              ...params.inputProps,
              id: 'place-search-input',
              autocomplete: 'new-password',
              sx: { maxWidth: '70%' },
            }}
            placeholder="Search by place, address or postcode"
            autoCapitalize="off"
          />
        )}
      />
    </Fade>
  )
}

export default PlaceSearch
