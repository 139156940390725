/* a react hook function called that returns an empty object */

import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { StrikePublic } from 'strikemap-common/lib/types'
import { fetchJson } from '../fetching'
import { getDistance } from '../geo'

export function useStrike() {
  const router = useRouter()
  const [locationPermission, setLocationPermission] = useState<string | null>(
    null
  )

  useEffect(() => {
    checkLocationPermission()
  }, [])

  async function getNearestStrike() {
    const position = await getCurrentPosition()

    const strike = await fetchJson('/api/search/strike', {
      method: 'post',
      data: {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },
    })
    const distance = getDistance(
      { lat: position.coords.latitude, lng: position.coords.longitude },
      strike
    )
    return { strike, distance }
  }

  function getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      )
    })
  }

  function gotoStrikeWithContext(lat: number, lng: number) {
    const filters = getQueryFilters()

    fetchJson('/api/search/strike', {
      method: 'post',
      data: { latitude: lat, longitude: lng },
    }).then((res: StrikePublic) => {
      if (!res?.id) throw new Error('No strike found')
      if (filters.wave)
        router.push(`/w/${router.query.waveId}/strikes/${res.id}`)
      else if (filters.dispute)
        router.push(`/d/${router.query.disputeId}/strikes/${res.id}`)
      else if (filters.union)
        router.push(`/union/${router.query.slug}/strikes/${res.id}`)
      else if (filters.campaign)
        router.push(`/s/${router.query.slug}/strikes/${res.id}`)
      else router.push(`/strikes/${res.id}`)
    })
  }

  function getQueryFilters() {
    // Filter by wave, according to current path
    const waveSearch = router.pathname.includes('/w/[waveId]')
    if (waveSearch) return { wave: { id: router.query.waveId } }

    // Filter by dispute, according to current path
    const disputeSearch = router.pathname.includes('/d/[disputeId]')
    if (disputeSearch) return { dispute: { id: router.query.disputeId } }

    // Filter by union, according to current path
    const unionSearch = router.pathname.includes('/union/[slug]')
    if (unionSearch) return { union: { slug: router.query.slug } }

    // Filter by campaign, according to current path
    const campaignSearch = router.pathname.includes('/s/[slug]')
    if (campaignSearch) return { campaign: { slug: router.query.slug } }

    return {}
  }

  function gotoStrikeByLocation() {
    if (locationPermission !== 'denied') {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          checkLocationPermission()
          gotoStrikeWithContext(
            position.coords.latitude,
            position.coords.longitude
          )
        },
        () => {
          checkLocationPermission()
        }
      )
    }
  }

  function checkLocationPermission() {
    if (!!navigator.permissions && 'geolocation' in navigator) {
      setLocationPermission('unavailable')
      navigator.permissions
        ?.query({ name: 'geolocation' })
        .then(function (permissionStatus) {
          setLocationPermission(permissionStatus.state)
        })
    } else {
      setLocationPermission('unavailable')
    }
  }

  return {
    gotoStrikeWithContext,
    gotoStrikeByLocation,
    getNearestStrike,
    locationPermission,
  }
}
