// A framer-motion based react component that renders a burger icon that morphs into a close icon on click

import { motion } from 'framer-motion'

const BurgerToClose = ({
  onClick,
  open,
}: {
  onClick: () => void
  open: boolean
}) => {
  return (
    <motion.div
      animate={open ? 'open' : 'closed'}
      onClick={onClick}
      style={{
        width: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <motion.svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M3 12H21"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={{
            closed: { d: 'M2 2.5L22 2.5' },
            open: { d: 'M3 16.5L17 2.5' },
          }}
          transition={{ duration: 0.1 }}
        />
        <motion.path
          d="M2 10H22"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <motion.path
          d="M3 12H21"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={{
            closed: { d: 'M2 17.5L22 17.5' },
            open: { d: 'M3 2.5L17 16.346' },
          }}
          transition={{ duration: 0.1 }}
        />
      </motion.svg>
    </motion.div>
  )
}

export default BurgerToClose
