import { AddAPhoto, Cancel, PlayCircleFilled } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { SIDEBAR_WIDTH, VIDEO_FEED_HEIGHT } from 'lib'
import { TypeMap } from 'mime'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { A11y, Navigation, Scrollbar, Virtual } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'

const PAD = 10
const SCROLL_HEIGHT = 20
const THUMB_SIZE = VIDEO_FEED_HEIGHT - PAD * 2 - SCROLL_HEIGHT

type Props = {
  feed: BoastFeedItem[]
  sidebar?: boolean
  form?: string
  inline?: boolean
  background?: string
}

type BoastSource = {
  mime_type: TypeMap
  url: string
}

type BoastFeedItem = {
  id: string
  status: 'published'
  submitted_at: string
  thumbnail: {
    url: string
    open_graph_url: string
    unprocessed_url: string
  }
  fields: {
    media: {
      sources: BoastSource[]
    }
  }
  urls: {
    detail: string
  }
}

export default function VideoFeed({
  sidebar,
  feed,
  form,
  inline,
  background,
}: Props) {
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState<number>()
  const [active, setActive] = useState<BoastFeedItem>()
  const [mediaLoading, setMediaLoading] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => setWidth(containerRef.current?.clientWidth), [containerRef])

  const slideWidth = 2 * PAD + THUMB_SIZE
  const nSlides = !!width ? Math.floor(width / slideWidth) : 0
  const showScrollbar = width && nSlides * slideWidth > width

  const handleClickThumbnail = (item: BoastFeedItem) => {
    setOpen(true)
    setActive(item)
    setMediaLoading(true)
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        position: inline ? 'relative' : 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 1000,
        marginLeft: sidebar ? `${SIDEBAR_WIDTH}px` : 0,
        height: VIDEO_FEED_HEIGHT - (showScrollbar ? 0 : 20),
        width: sidebar ? `calc(100vw - ${SIDEBAR_WIDTH}px)` : '100%',
        background: background || '#454545',
        padding: `${PAD}px`,
        '& .swiper': {
          paddingBottom: showScrollbar ? '24px' : 0,
        },
        '& .swiper-scrollbar': {
          background: '#727272',
          height: '8px',
          bottom: '5px',
        },
        '& .swiper-scrollbar-drag': {
          background: 'white',
        },
      }}
    >
      {!feed?.length && (
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 10,
            top: 5,
          }}
        >
          Add your own video support message here!
        </Typography>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 10001 }}
        PaperProps={{
          sx: {
            background: 'transparent',
            maxHeight: 'unset',
            maxWidth: 'unset',
            margin: desktop ? undefined : '5vw',
          },
        }}
      >
        <>
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 100,
              color: '#ffffffe6',
            }}
            onClick={() => setOpen(false)}
          >
            <Cancel sx={{ width: 40, height: 40 }} />
          </IconButton>
          <Box
            sx={{
              position: 'relative',
              background: 'black',
              borderRadius: '8px',
              width: desktop ? '70vh' : '90vw',
              height: desktop ? '70vh' : '90vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              objectFit: 'contain',
            }}
          >
            {!!active?.fields?.media ? (
              <>
                <video
                  controls
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                >
                  <source
                    src={active?.fields?.media?.sources[0].url}
                    type={active?.fields?.media?.sources[0].mime_type.toString()}
                  />
                </video>
              </>
            ) : (
              !!active?.thumbnail?.unprocessed_url && (
                <>
                  <Image
                    src={active.thumbnail.url}
                    alt="Supporter image"
                    fill
                    style={{ objectFit: 'cover' }}
                    onLoadingComplete={() => setMediaLoading(false)}
                  />
                  {!!mediaLoading && <CircularProgress />}
                </>
              )
            )}
          </Box>
          {form && (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              href={form}
              target="_blank"
              sx={{ marginTop: 1, borderRadius: '8px' }}
            >
              Add a video / photo of your own
            </Button>
          )}
        </>
      </Dialog>

      <Swiper
        // install Swiper modules
        modules={[Navigation, Scrollbar, A11y, Virtual]}
        spaceBetween={PAD}
        slidesPerView={nSlides}
        navigation
        virtual
        scrollbar={{ draggable: true }}
      >
        {!!feed?.length &&
          feed
            .filter((item) => !!item.thumbnail)
            .map((item) => (
              <SwiperSlide key={item.id}>
                <ButtonBase onClick={() => handleClickThumbnail(item)}>
                  <Image
                    src={item?.thumbnail?.open_graph_url}
                    alt={'Boast.io video'}
                    height={THUMB_SIZE}
                    width={THUMB_SIZE}
                    style={{ objectFit: 'cover' }}
                    placeholder="empty"
                  />
                  {!!item?.fields?.media && (
                    <PlayCircleFilled
                      sx={{
                        position: 'absolute',
                        width: 0.5 * THUMB_SIZE,
                        height: 0.5 * THUMB_SIZE,
                        color: '#ffffffc7',
                      }}
                    />
                  )}
                </ButtonBase>
              </SwiperSlide>
            ))}
        {form && (
          <SwiperSlide>
            <Box
              sx={{
                height: THUMB_SIZE,
                width: THUMB_SIZE,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton color="primary" href={form} target="_blank">
                <AddAPhoto
                  sx={{ height: 0.3 * THUMB_SIZE, width: 0.3 * THUMB_SIZE }}
                />
              </IconButton>
            </Box>
          </SwiperSlide>
        )}
      </Swiper>
    </Box>
  )
}
