import { FORMS, formValidation } from 'lib'
import RootContext from 'lib/browser/RootContext'
import _ from 'lodash'
import React, { useContext } from 'react'
import { TextInput } from './styled'

const UserDetails = ({
  onValidate,
}: {
  onValidate: (valid: boolean) => void
}) => {
  const { userDetails, setUserDetails = () => ({}) } = useContext(RootContext)
  const formRefs: { [key: string]: React.MutableRefObject<any> } = _.mapValues(
    FORMS.userDetails,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    () => React.useRef()
  )
  const [formErrors, setFormErrors] = useState(
    _.mapValues(FORMS.userDetails, () => null)
  )

  const formUpdate = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const noErrors = _.mapValues(FORMS.userDetails, () => null)
    const { name, value } = evt.target
    const newData = { ...userDetails, [name]: value }
    if (
      Object.keys(FORMS.userDetails).every(
        (key: string) => newData && !!newData[key]
      )
    ) {
      const res = formValidation(formRefs, formErrors)
      setFormErrors(res.valid ? noErrors : { ...formErrors, ...res.errors })
      onValidate(res.valid)
    } else {
      onValidate(false)
    }
    setUserDetails(newData)
  }

  return (
    <form>
      {Object.entries(FORMS.userDetails).map((field) => (
        <TextInput
          key={field[0]}
          error={formErrors[field[0]]}
          name={field[0]}
          label={field[1].label}
          variant="outlined"
          fullWidth
          autoComplete={field[1].autocomplete}
          onChange={formUpdate}
          inputRef={formRefs[field[0]]}
          value={userDetails && userDetails[field[0]]}
          required
          type={field[1].type}
        />
      ))}
    </form>
  )
}

export default UserDetails
