import { ButtonProps, Link, Tooltip } from '@mui/material'
import MenuButton from 'components/atoms/MenuButton'
import { MENU_SOCIAL } from 'lib'

export default function SocialFollowButtons({
  only,
  color = 'primary.main',
}: {
  only?: string[]
  color?: ButtonProps['color'] | string
}) {
  return (
    <>
      {MENU_SOCIAL.filter((service) =>
        !!only ? only.includes(service.name) : service
      ).map((service) => (
        <Tooltip key={service.name} title={service.tooltip}>
          <Link href={service.href} target="_blank" rel="noreferrer">
            <MenuButton sx={{ color }}>
              <service.icon sx={{ color }} />
            </MenuButton>
          </Link>
        </Tooltip>
      ))}
    </>
  )
}
