import { useTheme } from '@mui/styles'

const ClusterMarker: React.FC<{
  cluster: any
  pointsTotal: number
  onClick: (cluster: any) => void
  lat?: number
  lng?: number
}> = ({ cluster, pointsTotal, onClick }) => {
  const { point_count: pointCount } = cluster.properties
  const theme = useTheme()

  let diameter = 35 + (pointCount / pointsTotal) * 250
  diameter = diameter > 150 ? 150 : diameter

  return (
    <div
      className="cluster-marker"
      style={{
        zIndex: 100000,
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: '0.8rem',
        background: theme.palette.primary.main,
        opacity: 0.9,
        borderRadius: '50%',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: `${diameter}px`,
        height: `${diameter}px`,
      }}
      onClick={() => onClick(cluster)}
    >
      {pointCount}
    </div>
  )
}

export default ClusterMarker
