import EmailIcon from '@mui/icons-material/Email'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import EmailTarget from 'components/organisms/EmailTarget'
import { Types } from '..'

export const email_target = (): Types.Action => ({
  title: 'Email your MP ',
  icon: () => <EmailIcon />,
  dialog: {
    content: <EmailTarget />,
  },
})

export const email_solidarity = ({
  strike,
}: {
  strike: Types.StrikePublic
}): Types.Action => ({
  title: 'send solidarity email',
  icon: () => <EmailIcon />,
  props: {
    target: '_blank',
    href: `mailto:${strike.email_solidarity}?subject=Strike action at ${strike.employer_name}&body=Your message of solidarity here...`,
  },
})

export const launch_widget = ({
  campaign,
}: {
  campaign: Types.Campaign
}): Types.Action => ({
  title: campaign?.primary_cta_button_text || '',
  icon: () => <EmojiPeopleIcon />,
  widget: true,
})

const exports = { email_target, email_solidarity, launch_widget }

export default exports
