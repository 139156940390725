import styled from '@emotion/styled'
import { IconButton } from '@mui/material'

const MenuButton = styled(IconButton)(({ light }: { light?: string }) => ({
  backgroundColor: 'transparent',
  borderRadius: '6px',
  '&:hover': {
    filter: 'brightness(0.5)',
  },
  maxHeight: '36px',
  maxWidth: '36px',
  svg: {
    color: light ? 'white' : 'inherit',
  },
}))

export default MenuButton
