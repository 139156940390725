import _ from 'lodash'
import { createContext } from 'react'
import {
  Campaign,
  Errors,
  StrikePublic,
  UserDetails,
} from 'strikemap-common/lib/types'
import { FORMS } from './constants'

const RootContext = createContext<
  Partial<{
    errors: Errors
    clearErrors: () => void
    campaign: Campaign
    userDetails: UserDetails
    setUserDetails: (userDetails: UserDetails) => void
    selected?: StrikePublic | null
    widgetOpen?: boolean
    setWidgetOpen?: (newState: boolean) => void
  }>
>({
  userDetails: _.mapValues(FORMS.userDetails, () => ''),
  setUserDetails: () => null,
})

export const RootProvider = RootContext.Provider

export default RootContext
