import { Box, SxProps } from '@mui/material'

export function ThemeColourBox({
  desktopMenuWidth,
  sx,
  bg,
}: {
  desktopMenuWidth: number
  sx?: SxProps
  bg: string
}) {
  return (
    <Box
      sx={{
        width: `${desktopMenuWidth}px`,
        height: '100%',
        position: 'fixed',
        left: 0,
        zIndex: 1200,
        backgroundColor: bg,
        ...sx,
      }}
    />
  )
}
