import { Add } from '@mui/icons-material'
import {
  Box,
  Button,
  Link,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import BurgerToClose from 'components/atoms/BurgerToClose'
import SocialFollowButtons from 'components/molecules/SocialFollowButtons'
import { HEADER_HEIGHT, MENU_LINKS, SIDEBAR_WIDTH } from 'lib'
import { StrikeContext } from 'lib/browser/StrikeProvider'
import { default as NextLink } from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { Brand } from 'strikemap-common/lib/types'
import { ABProps, getActionButton } from './getActionButton'
import { Content, LinksSection, SocialSection } from './styled'

export default function ActionMenu() {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const { setMenuOpen, menuOpen, brand } = useContext(StrikeContext)
  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (open !== menuOpen) setOpen(menuOpen)
  }, [menuOpen])

  return (
    <Box>
      <Box
        sx={{
          padding: '1px 0',
        }}
      >
        <BurgerToClose
          open={open}
          onClick={() => {
            setOpen(!open)
            setMenuOpen(!open)
          }}
        />
      </Box>

      <SwipeableDrawer
        variant={'temporary'}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => null}
        anchor={desktop ? 'left' : 'bottom'}
        hideBackdrop={desktop ? undefined : true}
        PaperProps={{
          sx: {
            maxWidth: '450px',
            width: desktop ? '400px' : 'inherit',
            left: desktop ? `${SIDEBAR_WIDTH}px` : 0,
            top: desktop ? 0 : `${HEADER_HEIGHT}px`,
            minHeight: desktop ? '100vh' : '100%',
          },
        }}
        sx={{
          zIndex: desktop ? 1 : 1200,
        }}
      >
        <Content
          sx={{
            position: desktop ? 'absolute' : undefined,
            height: desktop ? '100%' : undefined,
          }}
          bg={brand?.colours?.strikeCard || theme.palette.secondary.main}
        >
          <NextLink href="/submit">
            <Button variant="contained" startIcon={<Add />} fullWidth>
              Submit a strike
            </Button>
          </NextLink>
          <ActionSection title="Stay up to date">
            {getBrandedButton({ name: 'mailingList' }, brand)}
            {getBrandedButton({ name: 'whatsappGroup' }, brand)}
          </ActionSection>
          <ActionSection title="Support StrikeMap">
            {getBrandedButton({ name: 'visitPicket' }, brand)}
            {getBrandedButton({ name: 'donate' }, brand)}
          </ActionSection>
          <ActionSection title="Get involved">
            {getBrandedButton({ name: 'becomeAffiliate' }, brand)}
            {getBrandedButton({ name: 'joinReps' }, brand)}
            {getBrandedButton({ name: 'joinStrikeClub' }, brand)}
          </ActionSection>
          <LinksSection theme={theme}>
            {MENU_LINKS.map((link, idx) => (
              <NextLink
                key={link.href || idx}
                href={link.disabled ? '' : link.href}
                style={{
                  pointerEvents: link.disabled ? 'none' : undefined,
                  cursor: link.disabled ? 'none' : undefined,
                }}
              >
                <Link
                  component="div"
                  variant="h2"
                  underline={link.disabled ? 'none' : 'hover'}
                  color={
                    link.disabled
                      ? '#dedede'
                      : brand?.colours?.buttons || 'primary.main'
                  }
                  sx={{
                    textDecoration: link.disabled ? 'none' : undefined,
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  {link.label}
                </Link>
              </NextLink>
            ))}
          </LinksSection>
          <SocialSection>
            <SocialFollowButtons
              color={brand?.colours?.buttons || 'primary.main'}
            />
          </SocialSection>
        </Content>
      </SwipeableDrawer>
    </Box>
  )
}

function getBrandedButton(args: ABProps, brand: Brand | undefined) {
  return getActionButton({
    ...args,
    color: brand?.colours?.buttons || 'primary.main',
  })
}

function ActionSection({
  title,
  children,
}: {
  title: string
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography variant="h2" color="textSecondary">
        {title}
      </Typography>
      {children}
    </Box>
  )
}
