import styled from '@emotion/styled'
import { Theme } from '@mui/material'
import Button from '@mui/material/Button'
import { HEADER_HEIGHT, HEADER_WITH_SEARCH_HEIGHT, SIDEBAR_WIDTH } from 'lib'

export const Root = styled.div<{
  theme: Theme
  desktop: boolean
  hideSearch?: boolean
  bg: string
  border: string
}>`
  position: fixed;
  z-index: 1201;
  display: flex;
  flex-direction: ${(props) =>
    props.theme.breakpoints.up('md') || props.desktop ? 'column' : 'row'};
  gap: 10px;
  padding: 6px 15px;
  top: 0;
  height: ${HEADER_HEIGHT}px;
  width: 100vw;
  background: ${(props) => props.bg};
  border-bottom: 6px solid;
  border-bottom-color: ${(props) => props.border};
  img {
    height: 38px;
    filter: saturate(0.95) brightness(0.9);
  }
  ${(props) => props.theme.breakpoints.up('md') || props.desktop} {
    width: ${`${SIDEBAR_WIDTH}px`};
    height: ${(props) =>
      props.hideSearch ? HEADER_HEIGHT : HEADER_WITH_SEARCH_HEIGHT}px;
    border-bottom: none;
  }
`

export const FloatingSearch = styled.div<{
  noHeader?: boolean
  paddingLeft?: number
  offset?: number
}>`
  position: absolute;
  text-align: center;
  top: ${(props) =>
    props.noHeader
      ? `${15 + (props.offset || 0)}px`
      : `${75 + (props.offset || 0)}px`};
  left: 0;
  width: 100vw;
  height: 40px;
  padding: 0 15px;
  padding-left: ${(props) => `${props.paddingLeft}px` || 'unset'};
  z-index: 10;
`

const HeaderButton = styled(Button)`
  color: white !important;
  margin: -10px 0 !important;
  padding: 0 15px !important;
  .MuiButton-label {
    b {
      margin-bottom: -2px;
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
  }
  .MuiSvgIcon-root {
    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }
`
HeaderButton.defaultProps = {
  color: 'inherit',
}

export { HeaderButton }
