import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const Root = styled(Box)<{
  height: number
}>`
  height: ${({ height }) => `${height}vh`};
  position: fixed;
  width: -webkit-fill-available;
  margin: 0;
  z-index: 20;
  overflow: hidden;
  overscroll-behavior: contain;
  transition: height 0.4s ease;
`
