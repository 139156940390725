'use client'

import axios from 'axios'
import { useEffect, useState } from 'react'
import { GMapCoord } from './types'

export default function useLocation() {
  const [gpsLocation, setGpsLocation] = useState<GMapCoord>()
  const [ipLocation, setIpLocation] = useState<GMapCoord>()
  const [locationPermission, setLocationPermission] = useState<string>()

  useEffect(() => {
    axios.get('https://ipapi.co/json/').then((response) => {
      setIpLocation({
        lat: response.data.latitude,
        lng: response.data.longitude,
      })
    })
    checkLocationPermission(navigator).then((permission) => {
      setLocationPermission(permission)
      if (permission !== 'denied') {
        navigator.geolocation.getCurrentPosition((position) => {
          setGpsLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        })
      }
    })
  }, [])

  return { location: gpsLocation || ipLocation, locationPermission }
}

export const checkLocationPermission = async (navigator: Navigator) => {
  if (!!navigator && navigator.permissions && 'geolocation' in navigator) {
    return await navigator.permissions
      .query({ name: 'geolocation' })
      .then(function (permissionStatus) {
        return permissionStatus.state
      })
  } else {
    return 'unavailable'
  }
}
