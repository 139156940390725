import styled from '@emotion/styled'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { Autocomplete } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

const inputStyles = `
background-color: white;
border-radius: 5px;
/* input:-webkit-autofill {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 1000px #ff3845 inset !important;
  background-color: #ff3845 !important;
  background-clip: content-box !important;
} */
.MuiInputBase-input {
  border-radius: 5px 0 0 5px;
  padding: 8px 15px;
  opacity: 1;
  ::placeholder {
    opacity: 1;
  }
}
& label.Mui-focused {
  color: black;
}
& .MuiInput-underline:after {
  border-bottom-color: transparent !important;
  border-bottom-width: 3px;
}
`

const noOutlineStyles = `
& .MuiOutlinedInput-root {
  padding-right: 5px !important;
  & fieldset {
    border-color: transparent;
  }
  &.Mui-focused fieldset {
    border-color: transparent;
  }
}
`

const StyledAutocomplete = styled(Autocomplete)<{ nooutline?: string }>`
  ${inputStyles}
  ${({ nooutline }) => nooutline === 'true' && noOutlineStyles};
`

const Postcode = styled(TextField)`
  ${inputStyles}
`

Postcode.defaultProps = {
  type: 'text',
  autoComplete: 'postal-code',
  fullWidth: true,
}

export const InputButton = styled(IconButton)`
  padding: 6px !important;
  margin-bottom: 2px !important;
`

export const GoIcon = styled(ArrowForwardIcon)``

export const LocateIcon = styled(LocationSearchingIcon)``

export const NoLocateIcon = styled(LocationDisabledIcon)``

export const Spinner = styled(CircularProgress)`
  margin: 6px;
`

export { Postcode, StyledAutocomplete }
