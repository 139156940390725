import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { HEADER_HEIGHT } from 'lib'

export const Content = styled(Box)(({ bg }: { bg: string }) => ({
  padding: '15px 15px 30px 15px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  backgroundColor: bg,
}))

export const LinksSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& > a': {
    height: `${HEADER_HEIGHT}px`,
  },
  h2: {
    lineHeight: '53px',
  },
}))

export const SocialSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
  justifyContent: 'center',
})
