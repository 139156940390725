import { Box, useMediaQuery, useTheme } from '@mui/material'
import { massAction } from '@prisma/client'
import MassActionMarker from 'components/atoms/MassActionMarker'
import { ThemeColourBox } from 'components/atoms/ThemeColourBox'
import MapDataSelector from 'components/molecules/MapDataSelector'
import MapLegend from 'components/molecules/MapLegend'
import GoToStrike from 'components/organisms/GoToStrike'
import VideoFeed from 'components/organisms/VideoFeed'
import Header from 'components/templates/Header'
import { HEADER_HEIGHT, SIDEBAR_WIDTH, VIDEO_FEED_HEIGHT } from 'lib'
import { fetchJson } from 'lib/browser/fetching'
import { getMapOverride } from 'lib/browser/map'
import { StrikeContext } from 'lib/browser/StrikeProvider'
import { useRouter } from 'next/router'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { UnionPopulated } from 'strikemap-common/lib/types'
import useSWR from 'swr'
import { FloatingSearch } from '../Header/styled'
import LayoutChildTheme from '../LayoutChildTheme'
import MapLibre from '../MapLibre'
import { onClickMarker } from './lib'

const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

export default function Layout({
  children,
  showMap = true,
  hideSearch,
  hideMapOnMobile,
}: {
  children: ReactNode
  showMap?: boolean
  hideSearch?: boolean
  hideMapOnMobile?: boolean
}) {
  const router = useRouter()
  const outerTheme = useTheme()
  const { embed, setEmbed, activeSearch, setBrand, brand } =
    useContext(StrikeContext)
  const desktop = useMediaQuery(outerTheme.breakpoints.up('md'))
  const hideMap = !showMap || (hideMapOnMobile && !desktop)
  const [loaded, setLoaded] = useState(false)
  const [mapData, setMapData] = useState<string>('massAction')

  // Get union according to path
  const { data: union } = useSWR<UnionPopulated>(
    router.pathname.includes('/union/[slug]') &&
      router.query.slug &&
      `/api/union/${router.query.slug}`,
    fetchJson,
    swrOptions
  )

  // Get mass actions according to union
  const hasMassActions = union?.features?.massActions
  const { data: massActions } = useSWR<massAction[]>(
    !!union?.id && hasMassActions && `/api/mass_actions?unionId=${union.id}`,
    fetchJson,
    swrOptions
  )

  // Video feed
  const { data: feed } = useSWR(
    union?.slug &&
      union?.features?.boast &&
      `/api/union/${union?.slug}/features/boast`,
    fetchJson,
    swrOptions
  )

  // Layout variables
  const isStrikeView = router.pathname.includes('/strikes/')
  const isUnionRoute = router.pathname.includes('/union/[slug]')
  const showSideBar = !!(
    (desktop && !embed) ||
    (desktop && embed && isStrikeView)
  )
  const restrictToEmbed = union?.features?.restrictToEmbed

  useEffect(() => {
    if (!!router.query.embedKey && !embed) {
      setEmbed(true)
    }
  }, [router.query, embed])

  useEffect(() => {
    if (isUnionRoute) {
      if (union?.brand && (restrictToEmbed ? embed : true))
        setBrand(union.brand)
      if (!!union?.slug && !loaded) setLoaded(true)
    } else if (!isUnionRoute) {
      setLoaded(true)
    }
  }, [router.pathname, union, loaded])

  // Get map override data
  let filteredMassActions = massActions
  if (mapData.includes('massAction.') && massActions) {
    filteredMassActions = massActions.filter(
      (item: any) =>
        item && item?.details?.date?.includes(mapData.split('.')[1])
    )
  }
  const overrideData =
    !!filteredMassActions?.length && mapData.includes('massAction')
      ? getMapOverride(filteredMassActions, MassActionMarker)
      : undefined

  return (
    <>
      <LayoutChildTheme brand={brand}>
        {loaded &&
          (embed ? (
            <FloatingSearch
              noHeader
              paddingLeft={showSideBar ? SIDEBAR_WIDTH + 50 : 15}
            >
              <GoToStrike
                defaultValue={activeSearch}
                hide={!!(hasMassActions && mapData !== 'strike')}
              />
            </FloatingSearch>
          ) : (
            <Header hideSearch={!!(hideMap || hideSearch)} brand={brand} />
          ))}

        {loaded && (
          <main>
            {desktop && !embed && (
              <ThemeColourBox
                desktopMenuWidth={SIDEBAR_WIDTH}
                bg={
                  brand?.colours?.strikeCard || outerTheme.palette.primary.main
                }
              />
            )}
            {!hideMap && <MapLegend />}
            <Box
              sx={{
                position: 'absolute',
                marginTop: desktop || embed ? 0 : `${HEADER_HEIGHT}px`,
                zIndex: 1,
                marginLeft: showSideBar ? `${SIDEBAR_WIDTH}px` : 0,
                marginBottom: !!feed && embed ? VIDEO_FEED_HEIGHT : 0,
                top: 0,
                overflowY: embed ? 'hidden' : 'auto',
                height:
                  !!feed && embed
                    ? `calc(100% - ${VIDEO_FEED_HEIGHT}px)`
                    : '100%',
                width: desktop
                  ? hideMap
                    ? '-webkit-fill-available'
                    : 'unset'
                  : '-webkit-fill-available',
              }}
            >
              <Box sx={{ position: 'relative', height: '100%' }}>
                {hideMap ? (
                  children
                ) : (
                  <MapLibre
                    height={100}
                    onClickMarker={(id: number) => onClickMarker(id, router)}
                    union={union}
                    override={overrideData}
                  />
                )}
                {hasMassActions && !isStrikeView && (
                  <MapDataSelector
                    selected={mapData}
                    setSelected={setMapData}
                    color={brand?.colours?.buttons}
                    massActions={massActions}
                  />
                )}
              </Box>
            </Box>

            {!!feed?.responses?.length &&
              !isStrikeView &&
              (restrictToEmbed ? !!embed : true) && (
                <VideoFeed
                  sidebar={showSideBar}
                  feed={feed.responses}
                  form={union?.features?.boast?.form}
                />
              )}

            {!hideMap && children}
          </main>
        )}
      </LayoutChildTheme>
    </>
  )
}
