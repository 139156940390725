import { Fade, LinearProgress } from '@mui/material'
import { ClusterMarker, StrikeMarker } from 'components/atoms'
import { MAP_CENTER, MAP_WIDE_ZOOM } from 'lib'
import { swrPost } from 'lib/browser/fetching'
import { getPointsTotal } from 'lib/browser/map'
import swrLaggy from 'lib/browser/react/swrLaggy'
import { StrikeContext } from 'lib/browser/StrikeProvider'
import { flatten } from 'lodash'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import ReactMap, {
  MapboxEvent,
  Marker,
  useMap,
  ViewStateChangeEvent,
} from 'react-map-gl'
import {
  GMapCoord,
  MapOverride,
  StrikeCluster,
  StrikePublic,
  UnionPopulated,
} from 'strikemap-common/lib/types'
import useDebounce from 'strikemap-common/lib/useDebounce'
import useLocation from 'strikemap-common/lib/useLocation'
import useSWR from 'swr'
import CampaignMarkers from './CampaignMarkers'
import CampaignOverlay from './CampaignOverlay'
import { Root } from './styled'

interface MapViewProps {
  height: number
  onClickMarker: (id: number) => void
  override?: MapOverride
  union?: UnionPopulated
}

export default function MapLibre({
  height,
  override,
  onClickMarker,
  union,
}: MapViewProps) {
  const { updateMarkerClicked, brand, showEndedStrikes } =
    useContext(StrikeContext)
  const [bbox, setBbox] = useState<number[] | undefined>()
  const router = useRouter()
  const { default: map } = useMap()
  const [zoom, setZoom] = useState<number>(MAP_WIDE_ZOOM)
  const [visiblePoints, setVisiblePoints] = useState<number>(0)
  const [customMarker, setCustomMarker] = useState<string>()
  const [swrData, setSwrData] = useState<any>(null)
  const debouncedSwrData = useDebounce(swrData, 250)
  const [loading, setLoading] = useState<boolean>(false)
  const { location } = useLocation()

  const { data: clusters, isLoading } = useSWR<StrikeCluster<StrikePublic>[]>(
    () =>
      debouncedSwrData &&
      !!debouncedSwrData?.bounds?.length &&
      !!debouncedSwrData?.zoom && {
        url: `/api/map`,
        data: debouncedSwrData,
      },
    swrPost,
    {
      use: [swrLaggy],
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setLoading(false)
  }, [router.asPath])

  useEffect(() => {
    setSwrData({
      bounds: bbox,
      zoom,
      asPath: router.asPath,
      pathname: router.pathname,
      query: {
        ...router.query,
        ...(showEndedStrikes ? { showEndedStrikes: true } : {}),
      },
    })
  }, [bbox, zoom, router, showEndedStrikes])

  useEffect(() => {
    if (clusters?.length) setVisiblePoints(getPointsTotal(clusters))
  }, [clusters])

  useEffect(() => {
    if (brand?.map?.marker) setCustomMarker(brand?.map?.marker)
  }, [union])

  useEffect(() => {
    if (map && location) {
      map.setZoom(5)
      map.panTo(location)
    }
  }, [location, map])

  const strikeMarkers = useMemo(
    () =>
      clusters?.map((cluster) => {
        const { cluster: isCluster, strikeId } = cluster.properties
        const [longitude, latitude] = cluster.geometry.coordinates

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              latitude={latitude}
              longitude={longitude}
              anchor="center"
            >
              <ClusterMarker
                cluster={cluster}
                pointsTotal={visiblePoints}
                onClick={() => handleClickCluster(cluster)}
              />
            </Marker>
          )
        } else {
          return (
            <Marker
              latitude={latitude}
              longitude={longitude}
              key={`strike-${strikeId}-marker`}
              anchor="center"
            >
              <StrikeMarker
                id={strikeId}
                onSelect={(id) => handleClickMarker(id)}
                strike={cluster.properties.strike}
                customMarker={customMarker}
              />
            </Marker>
          )
        }
      }),
    [clusters, visiblePoints]
  )

  return (
    <Root height={height}>
      <ReactMap // @ts-ignore
        mapLib={maplibregl}
        initialViewState={{
          longitude: MAP_CENTER.lng,
          latitude: MAP_CENTER.lat,
          zoom,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="https://tiles.stadiamaps.com/styles/osm_bright.json"
        onDragStart={() => setLoading(true)}
        onDragEnd={setBounds}
        onZoomStart={() => setLoading(true)}
        onZoomEnd={setBounds}
        onRender={setBounds}
      >
        {override
          ? override?.data.map((item) => (
              <Marker key={item.id} latitude={item.lat} longitude={item.lng}>
                <override.Marker customMarker={customMarker} {...item} />
              </Marker>
            ))
          : strikeMarkers}
        <CampaignOverlay />
        <CampaignMarkers />
      </ReactMap>
      {
        <Fade in={loading}>
          <LinearProgress
            color="secondary"
            sx={{
              height: '10px',
              position: 'fixed',
              zIndex: 2000,
              bottom: 0,
              right: 0,
              width: '100vw',
            }}
          />
        </Fade>
      }
    </Root>
  )

  function handleClickMarker(id: number) {
    if (router?.query?.id !== id.toString()) setLoading(true)
    onClickMarker(id)
    if (updateMarkerClicked) updateMarkerClicked(id)
  }

  function handleClickCluster(cluster: any) {
    const [longitude, latitude] = cluster.geometry.coordinates
    if (map) {
      map.setZoom(zoom + 2)
      map.panTo({ lat: latitude, lng: longitude })
    }
  }

  function setBounds(evt: ViewStateChangeEvent | MapboxEvent) {
    setBbox(
      flatten(
        Object.values(evt.target.getBounds()).map((c: GMapCoord) =>
          Object.values(c)
        )
      ) as number[]
    )
    setZoom(evt.target.getZoom())
  }
}
