import {
  Add,
  Email,
  EmojiPeople,
  Facebook,
  Favorite,
  Flag,
  Groups,
  Handshake,
  Instagram,
  // YouTube,
  Mail,
  People,
  Recommend,
  Twitter,
  VolunteerActivism,
  WhatsApp,
} from '@mui/icons-material'

type Coords = {
  lat: number
  lng: number
}

export const MAP_CENTER: Coords = {
  lat: 54.53409,
  lng: 0,
}
export const MAP_WIDE_ZOOM = 2
export const SIDEBAR_WIDTH = 400
export const HEADER_HEIGHT = 65
export const CAMPAIGN_BANNER_HEIGHT = 40
export const HEADER_WITH_SEARCH_HEIGHT = 135
export const VIDEO_FEED_HEIGHT = 140

export const appUrl = process.env.appUrl as string

export const FORMS = {
  userDetails: {
    name: {
      label: 'Your name',
      autocomplete: 'name',
      type: 'text',
    },
    email: {
      label: 'Email address',
      autocomplete: 'email',
      type: 'email',
    },
    postcode: {
      label: 'Postcode',
      autocomplete: 'postal-code',
      type: 'text',
    },
    address: {
      label: 'Address',
      autocomplete: 'street-address',
      type: 'text',
    },
  },
}

export const FOOTER_MENU = [
  {
    Icon: Add,
    label: 'Add a strike',
    href: '/submit',
  },
  {
    Icon: EmojiPeople,
    label: 'Become a supporter',
    href: 'https://actionnetwork.org/forms/support-strike-map',
  },
  {
    Icon: Favorite,
    label: 'Become a member',
    href: 'https://actionnetwork.org/fundraising/become-a-comrade-of-strike-map-uk',
  },
  {
    Icon: WhatsApp,
    label: 'Join our Whatsapp',
    href: 'https://chat.whatsapp.com/L4h31zC3RtfJWcClhFJCkh',
  },
  {
    Icon: People,
    label: 'Affiliate your union',
    href: 'https://actionnetwork.org/fundraising/help-us-build-our-worker-powered-map/',
  },
]

export const MENU_LINKS = [
  {
    label: 'Strike Stories',
    href: 'https://oldstrikemap.wordpress.com/stories/',
    disabled: false,
  },
  // {
  //   label: 'Advanced Search',
  //   href: '/search',
  //   disabled: true,
  // },
  {
    label: 'About Us',
    href: '/about',
    disabled: false,
  },
  {
    label: 'Our Supporters',
    href: '/supporters',
    disabled: false,
  },
]

export const MENU_SOCIAL = [
  {
    name: 'twitter',
    tooltip: 'Follow us on Twitter',
    icon: Twitter,
    href: 'https://twitter.com/strike_map/',
  },
  {
    name: 'facebook',
    tooltip: 'Like us on Facebook',
    icon: Facebook,
    href: 'https://www.facebook.com/strikemap/',
  },
  {
    name: 'instagram',
    tooltip: 'Follow us on Instagram',
    icon: Instagram,
    href: 'https://www.instagram.com/strike_map/',
  },
  /*   {
    name: 'youtube',
    tooltip: 'Subscribe to our YouTube channel',
    icon: YouTube,
    href: 'https://www.youtube.com/channel/UCI9zAo8iImwGDVAiDNlxfcA',
  }, */
]

export const ACTION_BUTTONS = {
  mailingList: {
    title: 'join our mailing list',
    icon: Mail,
    href: '/forms/support-strike-map',
  },
  whatsappGroup: {
    title: 'join our whatsapp group',
    icon: WhatsApp,
    href: 'https://chat.whatsapp.com/L4h31zC3RtfJWcClhFJCkh',
  },
  visitPicket: {
    title: 'visit a picket',
    icon: Flag,
    href: '/forms/visitapicket',
  },
  donate: {
    title: 'donate',
    icon: Recommend,
    href: '/forms/become-a-comrade-of-strike-map',
  },
  becomeAffiliate: {
    title: 'become a supporter',
    icon: VolunteerActivism,
    href: '/forms/help-us-build-our-worker-powered-map',
  },
  joinReps: {
    title: 'join our reps network',
    icon: Handshake,
    href: '/forms/national-reps-network',
  },
  solidarity: {
    title: 'send solidarity email',
    icon: Email,
    href: undefined,
  },
  joinStrikeClub: {
    title: 'join a strike club',
    icon: Groups,
    href: '/forms/join-strike-club',
  },
}
