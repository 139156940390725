import SendIcon from '@mui/icons-material/Send'
import Collapse from '@mui/material/Collapse'
import Slide from '@mui/material/Slide'
import Consents from 'components/molecules/Consents'
import Forms from 'components/templates/Forms'
import parse from 'html-react-parser'
import { Types } from 'lib'
import { fetchJson } from 'lib/browser/fetching'
import RootContext from 'lib/browser/RootContext'
import Mustache from 'mustache'
import { useContext, useEffect, useState } from 'react'
import template from './email-body'
import { Controls, Preview, Root, Separator, Submit } from './styled'

const EmailTarget = () => {
  const { campaign, userDetails } = useContext(RootContext)
  const [valid, setValid] = useState(false)
  const [target, setTarget] = useState<Types.Representative>()

  useEffect(() => {
    if (
      valid &&
      userDetails &&
      userDetails.postcode &&
      userDetails.postcode.replace(/ /g, '').length >= 5
    ) {
      fetchJson(`/api/representative/${userDetails.postcode}`).then((result) =>
        setTarget(result?.representative)
      )
    }
  }, [userDetails?.postcode])

  // TODO: Add a button 'lookup your representative' instead of look for filled fields

  return (
    <Root>
      <h4>{campaign?.primary_cta_text}</h4>
      <Forms.UserDetails onValidate={setValid} />
      <small>
        * Your name & address is required so your MP can verify you live within
        their constituency
      </small>
      <Slide direction="left" in={valid && !!target}>
        <Separator />
      </Slide>
      <Collapse in={valid && !!target}>
        <div>
          <p>Your email will look like this:</p>
          <Preview>
            <div className="line-display">
              <div className="field-name">To</div>receiver
            </div>
            <div className="line-display">
              <div className="field-name">Subject</div>Very long subject line
              for UI testing. Very overly long.
            </div>
            {parse(
              Mustache.render(template, {
                targetName: target?.name,
                userDetails: userDetails,
              })
            )}
          </Preview>
          <Controls>
            <Consents />
            <Submit color="primary" variant="contained" endIcon={<SendIcon />}>
              Send email
            </Submit>
          </Controls>
        </div>
      </Collapse>
    </Root>
  )
}

export default EmailTarget
