import { Box } from '@mui/material'
import CampaignBanner from 'components/molecules/CampaignBanner'
import { SIDEBAR_WIDTH } from 'lib'
import { useCampaigns } from 'lib/browser/CampaignProvider'
import { useResponsive } from 'lib/browser/react/useResponsive'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useRouter } from 'next/router'
import React from 'react'

const CampaignOverlay: React.FC = () => {
  const router = useRouter()
  const { desktop } = useResponsive()
  const { featuredCampaign } = useCampaigns()

  return (
    <>
      {featuredCampaign && desktop && router.route === '/' && (
        <Box
          sx={{
            position: 'absolute',
            width: SIDEBAR_WIDTH,
            bottom: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          <CampaignBanner campaign={featuredCampaign} />
        </Box>
      )}
    </>
  )
}

export default CampaignOverlay
