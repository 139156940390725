import { isEmpty } from 'lodash'

export function validateBase64Img(image: string) {
  return image.includes('data:image/') && image.includes('base64')
}

export function validateBrandColours(colours: { [key: string]: string }) {
  return (
    !!colours &&
    !isEmpty(colours) &&
    Object.values(colours).every(
      (colour) => colour?.length >= 7 && colour.includes('#')
    )
  )
}

export const contentPaths = {
  pageContent_home: '/',
  pageContent_about: '/about',
  pageContent_supporters: '/supporters',
}
