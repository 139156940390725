import { Chip, Grid, Tooltip } from '@mui/material'
import { StrikePublic } from 'strikemap-common/lib/types'

export default function StrikeTags({ strike }: { strike: StrikePublic }) {
  return (
    <Grid
      item
      container
      direction="row"
      flexWrap="wrap"
      spacing={1}
      sx={{ marginTop: '5px' }}
    >
      {strike?.action_reason?.split(';').map((reason, idx) => (
        <Grid item key={`action-reason-${idx}`}>
          <Tooltip title={reason}>
            <Chip label={reason} sx={{ maxWidth: '180px' }} />
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}
