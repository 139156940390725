import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding'
import haversine from 'haversine'
import { GMapCoord, StrikePublic } from 'strikemap-common/lib/types'

export const getDistance = (location: GMapCoord, strike: StrikePublic) =>
  Math.round(
    haversine(
      { latitude: location.lat, longitude: location.lng },
      { latitude: strike.location.lat, longitude: strike.location.lng },
      { unit: 'meter' }
    )
  )

export const formatLocation = (location: any) => ({
  lat: location?.latitude,
  lng: location?.longitude,
})

export const formatDistance = (distance: number) => {
  return distance > 1500
    ? `${(distance / 1000).toFixed(1)} km`
    : `${distance} m`
}

export function getCoordinatesFromPlace(place: GeocodeFeature) {
  return {
    lat: place.geometry.coordinates[1],
    lng: place.geometry.coordinates[0],
  }
}

export type GeocodeContext = GeocodeFeature & { short_code: string }
export type StrikeLocation = ReturnType<typeof getGeoDataFromPlace>

export function getGeoDataFromPlace(place: GeocodeFeature) {
  const geoData = {
    country: (
      place?.context?.find((c) => c.id.includes('country')) as GeocodeContext
    )?.short_code,
    region: place?.context?.find((c) => c.id.includes('region'))?.text,
    action_postcode: place.id.includes('postcode')
      ? place.text
      : place?.context?.find((c) => c.id.includes('postcode'))?.text,
    location: getCoordinatesFromPlace(place),
  }

  return geoData
}
