import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { massAction } from '@prisma/client'
import { uniq } from 'lodash'
import { useRef } from 'react'

type Props = {
  selected: string
  setSelected: (data: string) => void
  color?: string
  massActions?: massAction[]
}

export default function MapDataSelector({
  selected,
  setSelected,
  color,
  massActions,
}: Props) {
  const demosRef = useRef<HTMLButtonElement>(null)
  const buttonSx = {
    '&.Mui-selected': {
      backgroundColor: `${color || 'primary.main'} !important`,
      color: 'white',
      fontWeight: 800,
    },
  }

  const dates = uniq(massActions?.map((ma: any) => ma.details?.date))

  const noBottomCorners = {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }

  const noTopCorners = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }

  const demosOpen = selected?.includes('massAction')

  return (
    <Box
      sx={{
        width: '100vw',
        zIndex: 1000,
        position: 'absolute',
        bottom: '12px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box position="relative">
        <ToggleButtonGroup
          exclusive
          aria-label="Map display type"
          value={selected?.split('.')[0]}
          onChange={(evt, value) => {
            if (!!value) setSelected(value)
          }}
          sx={{ background: 'white' }}
        >
          <ToggleButton value="strike" sx={buttonSx}>
            Strikes
          </ToggleButton>

          <ToggleButton
            sx={{ ...buttonSx, ...(demosOpen && noTopCorners), width: '180px' }}
            value="massAction"
            onClick={(evt, value) => {
              if (selected !== value) setSelected(value)
            }}
            ref={demosRef}
          >
            Events
          </ToggleButton>
        </ToggleButtonGroup>
        {demosOpen && (
          <ToggleButtonGroup
            orientation="vertical"
            exclusive
            aria-label="Map display type"
            value={selected.includes('massAction.') && selected.split('.')[1]}
            onChange={(evt, value) => {
              if (!!value) setSelected('massAction.' + value)
            }}
            sx={{
              background: 'white',
              position: 'absolute',
              width: '180px',
              right: 0,
              bottom: 48,
            }}
          >
            {dates.map((date, idx) => (
              <ToggleButton
                key={date}
                sx={{
                  ...buttonSx,
                  ...(idx === dates.length - 1 && noBottomCorners),
                }}
                value={date}
              >
                {date}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </Box>
    </Box>
  )
}
