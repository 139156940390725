import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { createTheme, ThemeProvider } from '@mui/material'
import Head from 'next/head'
import { ReactNode } from 'react'
import { Brand } from 'strikemap-common/lib/types'
import { validateBrandColours } from 'strikemap-common/lib/utils'

function LayoutChildTheme({
  children,
  brand,
}: {
  children: ReactNode
  brand?: Brand
}): ReactJSXElement {
  if (!brand || Object.values(brand).every((val) => !val))
    return <>{children}</>

  const typography = !!brand?.fonts?.google?.typeface && {
    fontFamily: [brand.fonts.google.typeface, 'sans-serif'].join(','),
    fontSize: 14,
    h2: {
      fontSize: '1.6rem',
      lineHeight: '24px',
      fontWeight: 800,
    },
  }

  const palette = brand?.colours &&
    validateBrandColours(brand.colours) && {
      text: {
        primary: brand?.colours?.darkText as string,
        secondary: brand?.colours?.darkText as string,
      },
      primary: {
        main: brand?.colours?.mapClusters as string,
      },
      secondary: {
        main: brand?.colours?.buttons as string,
      },
    }

  return (
    <ThemeProvider
      theme={(theme) =>
        createTheme({
          ...theme,
          typography: typography || theme.typography,
          palette: palette || theme.palette,
        })
      }
    >
      <Head>
        {brand?.fonts?.google && (
          <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            {/* eslint-disable-next-line @next/next/no-page-custom-font */}
            <link
              href={`https://fonts.googleapis.com/css2?family=${String(
                brand.fonts.google?.typeface
              )}:wght@300;400;600;800&display=swap`}
              rel="stylesheet"
            />
          </>
        )}
      </Head>
      {children}
    </ThemeProvider>
  )
}

export default LayoutChildTheme
