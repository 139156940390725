import { BBox } from 'geojson'
import { flatten } from 'lodash'
import { LngLatBounds } from 'react-map-gl'
import {
  GenericMarker,
  MapOverride,
  StrikeCluster,
  StrikePublic,
} from 'strikemap-common/lib/types'
import { SIDEBAR_WIDTH } from './constants'

export type GenericCoord = {
  latitude?: string | number | null
  longitude?: string | number | null
}

export function createMapOptions() {
  return {
    gestureHandling: 'greedy',
    fullscreenControl: false,
    disableDefaultUI: true,
    clickableIcons: false,
    mapId: '1eed301fdddd2890',
  }
}

interface DisplayCenterProps {
  yOffset: number
  bounds: BBox
  strike: StrikePublic
  desktop?: boolean
  window: Window
}

export function getDisplayCenter({
  yOffset,
  bounds,
  strike,
  desktop,
  window,
}: DisplayCenterProps) {
  const offsetX = desktop ? SIDEBAR_WIDTH / 2 / window.innerWidth : 0
  const offsetY = desktop ? 0 : -yOffset
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const span = {
    lat: Math.abs(bounds[3] - bounds[1]),
    lng: Math.abs(bounds[2] - bounds[0]),
  }

  return {
    lat: strike.location.lat + span.lat * offsetY,
    lng: strike.location.lng + span.lng * offsetX,
  }
}

export function getMapOverride(
  modelData: GenericCoord & { [key: string]: any }[],
  Marker: (Props: GenericMarker) => JSX.Element
): MapOverride {
  return {
    data: modelData.map((a: any) => ({
      lat: a.latitude ? Number(a.latitude) : undefined,
      lng: a.longitude ? Number(a.longitude) : undefined,
      ...a,
    })),
    Marker,
  }
}

export function getPointsTotal(clusters: StrikeCluster<StrikePublic>[]) {
  return clusters.reduce((tot, cluster) => {
    if (cluster.properties.cluster)
      return (cluster?.properties?.point_count || 0) + tot
    else return 1 + tot
  }, 0)
}

export function lngLatToBbox(bounds: LngLatBounds): BBox {
  return flatten(Object.values(bounds).map((c) => Object.values(c))) as BBox
}
