import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const MarkerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...{ viewBox: '0 0 212.000000 212.000000' }} {...props}>
      <g
        transform="translate(0.000000,212.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M943 2025 c-67 -18 -115 -43 -183 -94 -147 -110 -222 -289 -199 -476
16 -125 155 -372 345 -610 158 -198 173 -206 243 -132 174 182 398 528 445
686 31 107 15 245 -42 361 -58 118 -213 239 -342 269 -63 15 -205 12 -267 -4z
m254 -213 c52 -27 116 -100 131 -150 37 -124 -18 -254 -133 -315 -56 -30 -164
-30 -221 0 -205 109 -192 396 23 478 51 20 147 13 200 -13z"
        />
        <path
          d="M557 853 c-8 -13 -237 -752 -237 -763 0 -7 232 -10 724 -10 l723 0
-73 368 c-41 202 -76 377 -79 390 -5 21 -10 22 -99 22 -70 0 -95 -3 -99 -14
-9 -22 -211 -286 -267 -348 -28 -32 -58 -58 -66 -58 -26 0 -198 205 -292 347
l-47 72 -92 1 c-50 0 -94 -3 -96 -7z"
        />
      </g>
    </SvgIcon>
  )
}

export default MarkerIcon
