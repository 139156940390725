import styled from '@emotion/styled'

export const Root = styled.div`
  a {
    color: #ff3845;
    text-decoration: underline;
  }
`

export const CheckRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  > div {
    font-size: 0.8rem;
  }
`
