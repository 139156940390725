import { ArrowDropDown, Campaign as CampaignIcon } from '@mui/icons-material'
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Link,
  Typography,
  useTheme,
} from '@mui/material'
import { motion, PanInfo } from 'framer-motion'
import { CAMPAIGN_BANNER_HEIGHT } from 'lib'
import { useResponsive } from 'lib/browser/react/useResponsive'
import { Campaign } from 'strikemap-common/lib/types'

type HeaderBannerProps = {
  campaign: Campaign
}

const CampaignBanner: React.FC<HeaderBannerProps> = ({ campaign }) => {
  const icon = campaign?.brand?.map?.marker
  const { title } = campaign
  const { cardText, cardUrlLabel, cardUrl } = campaign.content
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { desktop } = useResponsive()

  function onPan(_event: unknown, info: PanInfo) {
    if (info.delta.y > 0) setOpen(true)
    else if (info.delta.y < 0) setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <motion.div
        onPan={desktop ? undefined : onPan}
        style={{
          width: '100%',
          background: theme.palette.secondary.main,
          overflow: 'hidden',
        }}
        initial={{ height: CAMPAIGN_BANNER_HEIGHT }}
        animate={{
          height: open ? CAMPAIGN_BANNER_HEIGHT + 220 : CAMPAIGN_BANNER_HEIGHT,
        }}
        className="parent"
        onClick={() => setOpen(!open)}
      >
        <Box sx={{ px: 2, overflow: 'hidden' }}>
          <Box
            sx={{
              height: CAMPAIGN_BANNER_HEIGHT,
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
              }}
            >
              {/* eslint-disable-next-line */}
              {icon ? (
                <img
                  src={icon}
                  style={{ height: 24 }}
                  alt="campaign map marker"
                />
              ) : (
                <CampaignIcon sx={{ width: 24, height: 24, color: 'white' }} />
              )}
              <Typography
                color={theme.palette.grey[200]}
                fontWeight={600}
                fontSize={14}
              >
                {title}
              </Typography>
            </Box>
            <motion.div
              animate={
                desktop
                  ? { rotate: open ? 0 : 180 }
                  : { rotate: open ? 180 : 0 }
              }
            >
              <Box sx={{ height: '24px' }}>
                <ArrowDropDown sx={{ color: 'white' }} />
              </Box>
            </motion.div>
          </Box>
          <Box sx={{ pt: 1 }}>
            <Typography
              variant="body1"
              color={theme.palette.grey[200]}
              fontWeight={300}
            >
              {cardText}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'right',
              color: 'white',
              mt: 3,
            }}
          >
            <Link
              href={cardUrl}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <ButtonBase>
                <Typography
                  color={theme.palette.grey[200]}
                  fontWeight={600}
                  fontSize={14}
                  sx={{ borderBottom: '1px solid white' }}
                >
                  {cardUrlLabel}
                </Typography>
              </ButtonBase>
            </Link>
          </Box>
        </Box>
      </motion.div>
    </ClickAwayListener>
  )
}

export default CampaignBanner
