import EventBusy from '@mui/icons-material/EventBusy'
import {
  Box,
  Button,
  Fab,
  SvgIconProps,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material'
import MarkerIcon from 'components/atoms/StrikeIcons/MarkerIcon'
import { StrikeContext } from 'lib/browser/StrikeProvider'
import { startCase } from 'lodash'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'

export default function MapLegend() {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const { setShowEndedStrikes, showEndedStrikes, desktop } =
    useContext(StrikeContext)

  function handleClick() {
    setOpen(!open)
  }

  function renderItem(status: string, style: SvgIconProps['style']) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{startCase(status)} strikes</Typography>
        <MarkerIcon
          style={{
            width: 40,
            height: 40,
            ...style,
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <Fab
        onClick={handleClick}
        sx={{ position: 'fixed', right: 25, bottom: 25 }}
      >
        <MarkerIcon />
      </Fab>
      <SwipeableDrawer
        onClose={() => setOpen(false)}
        open={open}
        onOpen={() => setOpen(true)}
        anchor={desktop ? 'right' : 'bottom'}
      >
        <Box sx={{ display: 'flex', p: 3, flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 'bold',
                mb: 1,
              }}
            >
              Map Markers
            </Typography>
          </Box>
          <Button
            color="secondary"
            variant={showEndedStrikes ? 'contained' : 'outlined'}
            fullWidth={isMobile}
            startIcon={<EventBusy />}
            style={{ maxWidth: isMobile ? undefined : 200, height: 37 }}
            onClick={() => setShowEndedStrikes(!showEndedStrikes)}
          >
            Show ended strikes
          </Button>
          {renderItem('active', { color: theme.palette.secondary.main })}
          {renderItem('upcoming', {
            color: theme.palette.primary.main,
          })}
          {renderItem('ended', {
            color: 'grey',
          })}
        </Box>
      </SwipeableDrawer>
    </>
  )
}
