import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding'
import { Box, Collapse, Typography, useTheme } from '@mui/material'
import { formatDistance, getCoordinatesFromPlace } from 'lib/browser/geo'
import { useStrike } from 'lib/browser/react/useStrike'
import { useEffect, useState } from 'react'
import PlaceSearch from './PlaceSearch'
import { LocateIcon } from './PlaceSearch/styled'

interface Props {
  defaultValue?: string
  disabled?: boolean
  hide?: boolean
}

const GoToStrike = ({ defaultValue, disabled, hide }: Props) => {
  const { gotoStrikeWithContext, getNearestStrike, locationPermission } =
    useStrike()
  const [locatedToast, setLocatedToast] = useState<{
    distance: number
    region: string
    country?: string
  } | null>(null)
  const theme = useTheme()

  useEffect(() => {
    if (!!locatedToast) {
      setTimeout(() => {
        setLocatedToast(null)
      }, 3000)
    }
  }, [locatedToast])

  const onSubmitPlace = (place: GeocodeFeature) => {
    if (!place) throw new Error('No place selected')

    const { lat, lng } = getCoordinatesFromPlace(place)
    gotoStrikeWithContext(lat, lng)
  }

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <PlaceSearch
        onSubmitPlace={onSubmitPlace}
        onClickLocate={async () => {
          if (locationPermission !== 'granted') return
          const { strike, distance } = await getNearestStrike()
          setLocatedToast({
            distance,
            region: strike.region,
            country: strike.country,
          })
          gotoStrikeWithContext(strike.location.lat, strike.location.lng)
        }}
        defaultValue={defaultValue}
        disabled={disabled}
        hide={hide}
        noOutline
        submitOnSelect
      />
      <Collapse
        in={!!locatedToast}
        sx={{ position: 'absolute', top: 0, width: '100%' }}
      >
        <Box
          sx={{
            background: theme.palette.secondary.main,
            borderRadius: '4px',
            zIndex: 10,
            p: 1,
          }}
        >
          <Typography variant="body2" fontWeight="bold" color="primary">
            <LocateIcon
              sx={{ height: 17, width: 17, margin: '0 4px -3px 0' }}
            />
            Nearest strike located
          </Typography>
          <Typography variant="body2" color="primary">
            {formatDistance(locatedToast?.distance as number)} from you, in{' '}
            {locatedToast?.region}, {locatedToast?.country?.toUpperCase()}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  )
}

export default GoToStrike
