import { Campaign, Launch } from '@mui/icons-material'
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Grid,
  Link,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import Image from 'next/legacy/image'
import { useEffect, useRef, useState } from 'react'
import { useMap } from 'react-map-gl'
import { GenericMarker } from 'strikemap-common/lib/types'
import { getDirectionUrl } from '../lib/getDirectionUrl'

export default function CampaignMarker(
  props: GenericMarker & {
    preview?: boolean
    alwaysShowCard?: boolean
    defaultOpen?: boolean
    cardRef?: React.RefObject<HTMLDivElement>
  }
) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [showText, setShowText] = useState<boolean>(false)
  const map = useMap()

  const markerRef = useRef<HTMLDivElement>(null)

  const handleHover = (target: HTMLDivElement) => {
    if (anchorEl) handleClose()
    else setAnchorEl(target)
  }

  const handleClose = () => {
    if (props.alwaysShowCard) return
    setShowText(false)
    setAnchorEl(null)
  }

  useEffect(() => {
    if (props.alwaysShowCard && markerRef.current) {
      handleHover(markerRef.current)
    }
  }, [props.alwaysShowCard, markerRef.current])

  useEffect(() => {
    if (!props.alwaysShowCard) return
    setAnchorEl(null)

    setTimeout(() => {
      markerRef.current?.click()
    }, 100)
  }, [props.customMarker, props.alwaysShowCard])

  useEffect(() => {
    if (anchorEl) {
      map.current?.on('dragstart', handleClose)
      map.current?.on('zoomstart', handleClose)
    } else {
      map.current?.off('dragstart', handleClose)
      map.current?.off('zoomstart', handleClose)
    }
  }, [map.current, anchorEl])

  useEffect(() => {
    setShowText(!!props.defaultOpen)
  }, [props.defaultOpen])

  const open = Boolean(anchorEl)

  return (
    <>
      {props.customMarker ? (
        <div
          style={{
            maxWidth: '45px',
            width: '36px',
            height: '36px',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
          ref={markerRef}
          onClick={(e) => handleHover(e.currentTarget)}
        >
          <Image
            src={props.customMarker}
            alt="campaign marker"
            objectFit="contain"
            layout="fill"
            style={{ pointerEvents: 'all' }}
          />
        </div>
      ) : (
        <Box
          onClick={(e) => handleHover(e.currentTarget)}
          ref={markerRef}
          sx={{ pointerEvents: 'all', cursor: 'pointer' }}
        >
          <Campaign sx={{ width: 40, height: 40 }} />
        </Box>
      )}

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="right"
        sx={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Grid
            ref={props.cardRef}
            container
            component={Paper}
            direction="column"
            sx={{ padding: '12px', gap: 2, maxWidth: '320px', ml: 1 }}
          >
            <Grid item>
              <Typography variant="h6">{props.title}</Typography>
              <Typography variant="body1" fontWeight="bold">
                {props.placeName}
              </Typography>
              <Typography
                variant="body2"
                fontWeight="medium"
                fontStyle="italic"
              >
                {props.address}
              </Typography>
              <Collapse in={showText}>
                <Typography variant="body2" pt={1}>
                  {props.content}
                </Typography>
              </Collapse>
            </Grid>

            {!!props?.url && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  href={props.url}
                  target="_blank"
                  fullWidth
                >
                  {props.urlLabel || 'Take Action'}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                target="_blank"
                href={getDirectionUrl({ lat: props.lat, lng: props.lng })}
                fullWidth
                startIcon={<Launch />}
              >
                Get directions
              </Button>
            </Grid>
            <Grid item>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowText(!showText)}
              >
                {showText ? 'Hide' : 'Read more'}
              </Link>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
