// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mbxClient from '@mapbox/mapbox-sdk'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

export const mapboxClient = mbxClient({
  accessToken: process.env.NEXT_PUBLIC_MAPBOX_PUBLIC_TOKEN,
})

export const mapboxGeocodingService = mbxGeocoding(mapboxClient)
