export const formValidation = (
  formRefs: { [key: string]: React.MutableRefObject<any> },
  formErrors: { [key: string]: any }
) => {
  const valid = Object.values(formRefs)[0].current.form.reportValidity()
  if (!valid) {
    const newErrors = { ...formErrors }
    Object.keys(formErrors).forEach(
      (key: string) =>
        (newErrors[key] =
          typeof formRefs[key].current === 'undefined' ||
          !formRefs[key].current.validity.valid)
    )
    return { valid: false, errors: newErrors }
  } else {
    return { valid: true }
  }
}
