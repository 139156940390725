import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

export const Controls = styled.div`
  width: 100%;
  text-align: right;
  padding: 16px 0;
  button {
    background-color: #ff3845 !important;
  }
`

const Preview = styled(Paper)`
  background-color: #ededed !important;
  padding: 20px;
  font-size: 0.8rem;
  max-height: 50vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  p {
    font-size: 0.8rem !important;
  }
  .line-display {
    min-height: 50px;
    padding: 10px 0 10px 70px;
    width: 100%;
    border-bottom: 1px solid grey;
    position: relative;
    > div.field-name {
      position: absolute;
      left: 0;
      font-weight: 600;
      width: 70px;
    }
  }
`
Preview.defaultProps = { elevation: 2 }

export const Separator = styled.div`
  height: 3px;
  background-color: #ff3845 !important;
  margin: 20px 0 10px 0 !important;
  width: 10%;
  align-self: center;
`

export const Submit = styled(Button)`
  margin-right: 11px !important;
`

export { Preview }
