import { Launch } from '@mui/icons-material'
import { Button, Grid, Popover, Typography } from '@mui/material'
import Image from 'next/legacy/image'
import defaultMarker from 'public/marker.png'
import React, { useState } from 'react'
import { getDirectionUrl } from 'strikemap-common/lib/getDirectionUrl'
import { GenericMarker } from 'strikemap-common/lib/types'
import { MarkerStyled } from './StrikeMarker'

export default function MassActionMarker(props: GenericMarker) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <MarkerStyled>
        <Image
          src={props.customMarker || defaultMarker}
          alt="strike marker"
          objectFit="contain"
          layout="fill"
          style={{ pointerEvents: 'all' }}
          onClick={handleHover}
        />
      </MarkerStyled>
      {anchorEl && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableScrollLock
          BackdropProps={{ onDrag: handleClose, onTouchStart: handleClose }}
          PaperProps={{ sx: { maxWidth: '280px' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <Grid container direction="column" sx={{ padding: '12px', gap: 2 }}>
            <Grid item>
              <Typography variant="body2">Mass Action</Typography>
              <Typography variant="h6">{props.title}</Typography>
              {props?.details?.date && (
                <Typography variant="h6" fontWeight={600}>
                  {props?.details?.date}
                </Typography>
              )}
              {props?.details?.startTime && (
                <Typography variant="h6" fontWeight={600}>
                  {props?.details?.startTime}
                </Typography>
              )}
              {props?.details?.description && (
                <Typography variant="body2">
                  {props?.details?.description}
                </Typography>
              )}
            </Grid>

            {!!props?.url && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  href={props.url}
                  target="_blank"
                  fullWidth
                >
                  more details
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                target="_blank"
                href={getDirectionUrl({ lat: props.lat, lng: props.lng })}
                fullWidth
                startIcon={<Launch />}
              >
                Get directions
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  )
}
