import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'

export const TextInput = styled(TextField)`
  margin-bottom: 10px !important;
  input:-webkit-autofill {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    background-color: white !important;
    background-clip: content-box !important;
  }
`
